var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":"","rounded":"","outlined":_vm.$vuetify.theme.dark}},[_c('v-toolbar-title',{staticClass:"display-1 font-weight-medium"},[_vm._v(" Packages")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"color":"primary","text":"","to":"packages/new","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")]),_vm._v("New Package")],1),(_vm.packages.length)?_c('div',{staticClass:"pageHeaderListWrapper"},[_c('v-list-item',{attrs:{"three-line":"","dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Total Packages: "+_vm._s(_vm.packages.length))]),_c('v-list-item-subtitle',[_vm._v(" Available Packages: "+_vm._s(_vm.packages.filter(function (i) { return i.available; }).length)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Non-Available Packages: "+_vm._s(_vm.packages.filter(function (i) { return !i.available; }).length)+" ")])],1)],1)],1):_vm._e()],1)],1),(!_vm.$vuetify.theme.dark)?_c('v-divider'):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"border",attrs:{"sort-by":"inventoryProperties","loading":_vm.loading,"search":_vm.packageSearch,"dense":"","headers":[
        { text: 'Package Code', value: 'packageCode' },
        { text: 'Destination', value: 'destination' },
        { text: 'Number of Nights', value: 'numberNights' },
        { text: 'Cost to Org.', value: 'costToOrg' },
        { text: 'Available', value: 'available' },
        { text: '# Inventory Properties', value: 'inventoryProperties' } ],"items":_vm.packages,"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Packages")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"append-icon":"mdi-magnify","label":"Search Packages","single-line":"","hide-details":"","dense":"","outlined":"","clearable":"","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.packageSearch),callback:function ($$v) {_vm.packageSearch=$$v},expression:"packageSearch"}})],1)]},proxy:true},{key:"item",fn:function(data){return [[_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(("packages/" + (data.item.id)))}}},[_c('td',[_vm._v(" "+_vm._s(data.item.packageCode.toUpperCase())+" ")]),_c('td',[_vm._v(" "+_vm._s(data.item.destination)+" ")]),_c('td',[_vm._v(" "+_vm._s(data.item.numberNights)+" ")]),_c('td',[_vm._v(" "+_vm._s(data.item.costToOrg)+" ")]),_c('td',[_c('v-icon',{attrs:{"small":"","color":data.item.available ? 'success' : 'error'}},[_vm._v(_vm._s(data.item.available ? "mdi-check-circle" : "mdi-close-circle"))])],1),_c('td',{class:data.item.inventoryProperties.length == 0
                  ? 'red lighten-2'
                  : 'green lighten-2'},[_vm._v(" "+_vm._s(data.item.inventoryProperties.length)+" ")])])]]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" No Packages Created")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }