<template>
  <v-row>
    <!-- page header -->
    <v-col cols="12">
      <v-toolbar flat rounded :outlined="$vuetify.theme.dark">
        <v-toolbar-title class="display-1 font-weight-medium">
          Packages</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            color="primary"
            text
            to="packages/new"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>New Package</v-btn
          >
          <div class="pageHeaderListWrapper" v-if="packages.length">
            <v-list-item three-line dense>
              <v-list-item-content>
                <v-list-item-title
                  >Total Packages: {{ packages.length }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  Available Packages:
                  {{ packages.filter((i) => i.available).length }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Non-Available Packages:
                  {{ packages.filter((i) => !i.available).length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider v-if="!$vuetify.theme.dark" />
    </v-col>
    <!-- page header -->

    <!-- event reports -->
    <v-col cols="12">
      <v-data-table
        sort-by="inventoryProperties"
        :loading="loading"
        :search="packageSearch"
        dense
        :headers="[
          { text: 'Package Code', value: 'packageCode' },
          { text: 'Destination', value: 'destination' },
          { text: 'Number of Nights', value: 'numberNights' },
          { text: 'Cost to Org.', value: 'costToOrg' },
          { text: 'Available', value: 'available' },
          { text: '# Inventory Properties', value: 'inventoryProperties' },
        ]"
        :items="packages"
        :items-per-page="-1"
        class="border"
      >
        <!-- title -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Packages</v-toolbar-title>
            <v-spacer />
            <v-text-field
              append-icon="mdi-magnify"
              label="Search Packages"
              single-line
              hide-details
              dense
              outlined
              v-model="packageSearch"
              clearable
              style="max-width: 500px"
              :loading="loading"
              :disabled="loading"
            ></v-text-field>
          </v-toolbar>
        </template>
        <!-- items -->
        <template v-slot:item="data">
          <template>
            <tr
              @click="$router.push(`packages/${data.item.id}`)"
              style="cursor: pointer"
            >
              <td>
                {{ data.item.packageCode.toUpperCase() }}
              </td>
              <td>
                {{ data.item.destination }}
              </td>
              <td>
                {{ data.item.numberNights }}
              </td>
              <td>
                {{ data.item.costToOrg }}
              </td>
              <td>
                <v-icon
                  small
                  :color="data.item.available ? 'success' : 'error'"
                  >{{
                    data.item.available
                      ? "mdi-check-circle"
                      : "mdi-close-circle"
                  }}</v-icon
                >
              </td>
              <td
                :class="
                  data.item.inventoryProperties.length == 0
                    ? 'red lighten-2'
                    : 'green lighten-2'
                "
              >
                {{ data.item.inventoryProperties.length }}
              </td>
            </tr>
          </template>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <span class="font-weight-bold"> No Packages Created</span>
        </template>
      </v-data-table>
    </v-col>
    <!-- packages -->
  </v-row>
</template>

<script>
import { mainApp } from "@/firebase";
const retailPackages = mainApp
  .firestore()
  .collection("packages")
  .orderBy("packageCode");
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind("packages", retailPackages);
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    packages: [],
    packageSearch: "",
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
